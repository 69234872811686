.drawer {
  --drawer-scale: 0.1;
  --drawer-width: calc(var(--thumbnail-width) + var(--chakra-space-4) + var(--chakra-space-4));

  background-color: white;
  border-right: 1px solid var(--sii-colors-border);
  box-shadow: -40px 0 0 0 white;
  height: var(--slider-height);
  overflow: hidden;
  width: var(--drawer-width);
}