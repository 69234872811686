:root {
  --header-height: 64px;

  
  --sii-animation-enter-speed: 125ms;
  --sii-animation-exit-speed: 450ms;
  --sii-animation-enter: 125ms ease-out;
  --sii-animation-exit: 450ms ease-in;
  --sii-boxshadow-light: rgb(0 0 0 / 5%) 0 10px 10px -5px;
  --sii-colors-border: var(--chakra-colors-secondary-200);
  --sii-font-code: SF Mono,Monaco,Inconsolata,Fira Mono,Droid Sans Mono,Source Code Pro,monospace;
  --sii-gradient-error: linear-gradient(to right, var(--chakra-colors-red-300), var(--chakra-colors-red-500), var(--chakra-colors-orange-500));
  --sii-gradient-info: linear-gradient(to right, var(--chakra-colors-cyan-500), var(--chakra-colors-blue-500), var(--chakra-colors-brand-600));
  --sii-gradient-neutral: linear-gradient(to right, var(--chakra-colors-gray-100), var(--chakra-colors-gray-200));
  --sii-gradient-success: linear-gradient(to right, var(--chakra-colors-green-500), var(--chakra-colors-green-400));
  --sii-gradient-warning: linear-gradient(to right, var(--chakra-colors-yellow-400) 20%, var(--chakra-colors-yellow-500), var(--chakra-colors-yellow-600));

  --slider-height: calc(100vh - var(--header-height));
  --slider-width: 100%;

  /** @credit https://www.joshwcomeau.com/shadow-palette/ */
  --shadow-color: 0deg 0% 0%; /* TODO: Convert background color to HSL */
  --shadow-opacity: 0.1; /* TODO: Once we have custom colors, improve shadow opacity */
  --sii-boxshadow-elevation-low:
    0px 0.3px 0.3px hsl(var(--shadow-color) / 0.34),
    0px 0.5px 0.6px -1.2px hsl(var(--shadow-color) / 0.34),
    0px 1.3px 1.5px -2.5px hsl(var(--shadow-color) / 0.34);
  --sii-boxshadow-elevation-medium:
    0px 0.3px 0.3px hsl(var(--shadow-color) / 0.36),
    0px 1.1px 1.2px -0.8px hsl(var(--shadow-color) / 0.36),
    0.1px 2.7px 3px -1.7px hsl(var(--shadow-color) / 0.36),
    0.2px 6.5px 7.3px -2.5px hsl(var(--shadow-color) / 0.36);
  --sii-boxshadow-elevation-high:
    0px 0.3px 0.3px hsl(var(--shadow-color) / var(--shadow-opacity)),
    0.1px 1.9px 2.1px -0.4px hsl(var(--shadow-color) / var(--shadow-opacity)),
    0.1px 3.5px 3.9px -0.7px hsl(var(--shadow-color) / var(--shadow-opacity)),
    0.2px 5.8px 6.5px -1.1px hsl(var(--shadow-color) / var(--shadow-opacity)),
    0.3px 9.3px 10.5px -1.4px hsl(var(--shadow-color) / var(--shadow-opacity)),
    0.5px 14.5px 16.3px -1.8px hsl(var(--shadow-color) / var(--shadow-opacity)),
    0.8px 22.1px 24.9px -2.1px hsl(var(--shadow-color) / var(--shadow-opacity)),
    1.2px 32.6px 36.7px -2.5px hsl(var(--shadow-color) / var(--shadow-opacity));


    --drawer-scale: 0.1;
    --drawer-width: calc(var(--thumbnail-width) + var(--chakra-space-2) + var(--chakra-space-4));
    --thumbnail-height: 4rem;
    --thumbnail-width: calc(var(--thumbnail-height) * 16 / 10); // 16:10 aspect ratio (looks slightly better than 16:9)
  
}

.callOut {
  display: none;
}

/* Safari */
:-webkit-full-screen,
/* IE11 */
:-ms-fullscreen,
/* Standard syntax */
:fullscreen {
  background-color: white;
}

html {
  // TODO: Go back to responsive font sizes? Seemed to cause some oddities...
  // @see https://bits.theorem.co/css-pro-tips-responsive-font-sizes-and-when-to-use-which-units/
  // font-size: calc(0.125em + 1vw);
  font-size: 16px;
}
button { font-size: inherit }
input { font-size: inherit }
/* End responsive font sizes */

body {
  overflow-x: hidden;
}

ul, ol, ul ul, ol ul, ol ol, ul ol {
  margin-left: 15px;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

main.page {
  display: grid;
  grid-template-columns: 0fr 1fr;

  & > div:first-child {
    grid-column: 1 / 3;
  }
}

.custom-scroll::-webkit-scrollbar {
  background-color: transparent;
  padding: 1px;
  width: 5px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;

}
.custom-scroll:hover::-webkit-scrollbar-thumb {
  background-color: var(--chakra-colors-secondary-50);
}

.token-line:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
